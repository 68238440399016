import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import Stand from '../../public/images/princess-stand.png';
import Walk1 from '../../public/images/walkingMan1.png';
import Walk2 from '../../public/images/walkingMan2.png';
import Jump from '../../public/images/jumpingMan.png';
import Villian from '../../public/images/villianStanding.png';
import { config } from '../components/config';

class Game extends Component {
  constructor(props) {
    super(props);
    this.appendCharacterToCanvas = this.appendCharacterToCanvas.bind(this);
    this.saveCanvasHandler = this.saveCanvasHandler.bind(this);
    this.state = {
      gameId: uuidv1(),
      level1Text: '',
      level2Text: '',
      level3Text: '',
      heroName: 'hero',
      villianName: 'villian',
      cityName: 'city',
      trophy: 'prize',
      enemy: '',
      customText: false,
      uncroppedImage: null,
      uncroppedImage2: null,
      heroURL: null,
      villianURL: null,
      heroSliderVisible: false,
      heroSliderValue: 1,
      heroRotaterValue: 0,
      villianSliderVisible: false,
      villianSliderValue: 1,
      villianRotaterValue: 0,
      faceOnly: null,
      heroStandSprite: null,
      heroWalk1Sprite: null,
      heroWalk2Sprite: null,
      heroJumpSprite: null,
      villianSprite: null,
      heroDrag: false,
      villianDrag: false,
      mouseX: 0,
      mouseY: 0,
      villianMouseX: 0,
      villianMouseY: 0,
      heroImageOffsetX: 0,
      heroImageOffsetY: 0,
      heroImageOffsetXOld: 0,
      heroImageOffsetYOld: 0,
      heroImageOffsetXTotal: 0,
      heroImageOffsetYTotal: 0,
      villianImageOffsetX: 0,
      villianImageOffsetY: 0,
      villianImageOffsetXOld: 0,
      villianImageOffsetYOld: 0,
      villianImageOffsetXTotal: 0,
      villianImageOffsetYTotal: 0,
      canSubmit: false,
      heroNameError: false,
      villianNameError: false,
      cityNameError: false,
      trophyError: false,
      level1TextError: false,
      level2TextError: false,
      level3TextError: false,
      enemyError: false,
    };
  }

  componentDidMount() {
    const overlayCanvas = document.getElementById('hero-cropper-overlay');
    overlayCanvas.addEventListener('mousedown', (e) => {
      this.setState({
        heroDrag: true,
        mouseX: e.clientX,
        mouseY: e.clientY,
      });
    });
    overlayCanvas.addEventListener('mouseup', () => {
      this.setState({
        heroDrag: false,
        heroImageOffsetX: 0,
        heroImageOffsetY: 0,
        heroImageOffsetXOld: 0,
        heroImageOffsetYOld: 0,
        heroImageOffsetXTotal: this.state.heroImageOffsetXTotal + this.state.heroImageOffsetX,
        heroImageOffsetYTotal: this.state.heroImageOffsetYTotal + this.state.heroImageOffsetY,
      });
    });
    overlayCanvas.addEventListener('mouseout', () => {
      this.setState({
        heroDrag: false,
        heroImageOffsetX: 0,
        heroImageOffsetY: 0,
        heroImageOffsetXOld: 0,
        heroImageOffsetYOld: 0,
        heroImageOffsetXTotal: this.state.heroImageOffsetXTotal + this.state.heroImageOffsetX,
        heroImageOffsetYTotal: this.state.heroImageOffsetYTotal + this.state.heroImageOffsetY,
      });
    });
    overlayCanvas.addEventListener('mousemove', (e) => {
      if (!this.state.heroDrag) return;

      this.setState({
        heroImageOffsetXOld: this.state.heroImageOffsetX,
        heroImageOffsetYOld: this.state.heroImageOffsetY,
        heroImageOffsetX: e.clientX - this.state.mouseX,
        heroImageOffsetY: e.clientY - this.state.mouseY,
      }, () => {
        this.sliderChangeHandler(this.state.heroSliderValue, true);
      });
    });
    overlayCanvas.addEventListener('wheel', (e) => {
      e.preventDefault();
      const heroSliderValue = e.deltaY > 0 ? this.state.heroSliderValue + 0.01 : this.state.heroSliderValue - 0.01;
      this.sliderChangeHandler(heroSliderValue, true);
    });
    const villianOverlayCanvas = document.getElementById('villian-cropper-overlay');
    villianOverlayCanvas.addEventListener('mousedown', (e) => {
      this.setState({
        villianDrag: true,
        villianMouseX: e.clientX,
        villianMouseY: e.clientY,
      });
    });
    villianOverlayCanvas.addEventListener('mouseup', () => {
      this.setState({
        villianDrag: false,
        villianImageOffsetX: 0,
        villianImageOffsetY: 0,
        villianImageOffsetXOld: 0,
        villianImageOffsetYOld: 0,
        villianImageOffsetXTotal: this.state.villianImageOffsetXTotal + this.state.villianImageOffsetX,
        villianImageOffsetYTotal: this.state.villianImageOffsetYTotal + this.state.villianImageOffsetY,
      });
    });
    villianOverlayCanvas.addEventListener('mouseout', () => {
      this.setState({
        villianDrag: false,
        villianImageOffsetX: 0,
        villianImageOffsetY: 0,
        villianImageOffsetXOld: 0,
        villianImageOffsetYOld: 0,
        villianImageOffsetXTotal: this.state.villianImageOffsetXTotal + this.state.villianImageOffsetX,
        villianImageOffsetYTotal: this.state.villianImageOffsetYTotal + this.state.villianImageOffsetY,
      });
    });
    villianOverlayCanvas.addEventListener('mousemove', (e) => {
      if (!this.state.villianDrag) return;
      this.setState({
        villianImageOffsetXOld: this.state.villianImageOffsetX,
        villianImageOffsetYOld: this.state.villianImageOffsetY,
        villianImageOffsetX: e.clientX - this.state.villianMouseX,
        villianImageOffsetY: e.clientY - this.state.villianMouseY,
      }, () => {
        this.sliderChangeHandler(this.state.villianSliderValue, false);
      });
    });
    villianOverlayCanvas.addEventListener('wheel', (e) => {
      e.preventDefault();
      const villianSliderValue = e.deltaY > 0 ? this.state.villianSliderValue + 0.01 : this.state.villianSliderValue - 0.01;
      this.sliderChangeHandler(villianSliderValue, false);
    });
  }

  appendCharacterToCanvas(isHero) {
    let mainCanvas;
    let overlayCanvas;
    if (isHero) {
      this.setState({ sliderVisible: true });
      mainCanvas = document.getElementById('hero-cropper');
      overlayCanvas = document.getElementById('hero-cropper-overlay');
    } else {
      this.setState({ villianSliderVisible: true });
      mainCanvas = document.getElementById('villian-cropper');
      overlayCanvas = document.getElementById('villian-cropper-overlay');
    }
    const context = mainCanvas.getContext('2d');
    const imageObj = new Image();
    // let ozgeHandler = this.ozgeHandler

    imageObj.onload = function (sliderValue) {
      // draw cropped image
      const destX = 0;
      const destY = 0;
      mainCanvas.width = 578;
      mainCanvas.height = 500;
      const destWidth = imageObj.width;
      const destHeight = imageObj.height;

      // context.clearRect(0, 0, heroCanvas.width, heroCanvas.height)
      context.drawImage(imageObj, destX, destY, destWidth, destHeight);
      overlayCanvas.width = 578;
      overlayCanvas.height = 500;
      const context2 = overlayCanvas.getContext('2d');
      // context2.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height)
      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;
      context2.beginPath();
      context2.rect(0, 0, overlayCanvas.width, overlayCanvas.height);
      context2.fillStyle = 'rgba(0, 0, 0, 0.8)';// rgba(10,10,10,0.7);
      context2.fill();

      // begin custom shape
      context2.beginPath();
      context2.moveTo(200, 80);
      context2.quadraticCurveTo(280, 80, 280, 160);
      context2.quadraticCurveTo(280, 320, 200, 320);
      context2.quadraticCurveTo(120, 320, 120, 160);
      context2.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context2.closePath();
      context2.lineWidth = 2;
      context2.strokeStyle = '#F4F4F4';
      context2.stroke();
      context2.globalCompositeOperation = 'destination-out';
      context2.fillStyle = 'rgba(255, 255, 255, 1)';
      context2.fill();
      // let ozge = overlayCanvas.toDataURL();
      // ozgeHandler(ozge)
    };
    if (isHero) {
      imageObj.src = this.state.heroURL;
    } else {
      imageObj.src = this.state.villianURL;
    }
  }

  saveCanvasHandler(sprite, number) {
    if (number === 1) {
      this.setState({
        heroStandSprite: sprite,
      });
    } else if (number === 2) {
      this.setState({
        heroWalk1Sprite: sprite,
      });
    } else if (number === 3) {
      this.setState({
        heroWalk2Sprite: sprite,
      });
    } else if (number === 4) {
      this.setState({
        heroJumpSprite: sprite,
      });
    } else if (number === 5) {
      this.setState({
        villianSprite: sprite,
      });
    } else if (number === 6) {
      this.setState({
        faceOnly: sprite,
      });
    }
  }

  uploadHandler(e, isHero) {
    window.URL = window.URL || window.webkitURL;
    const fileURL = window.URL.createObjectURL(e.target.files[0]);
    // Create a data URL from the image file
    if (isHero) {
      this.setState({
        uncroppedImage: e.target.files[0],
        heroURL: fileURL,
      }, () => {
        this.appendCharacterToCanvas(true);
      });
    } else if (!isHero) {
      this.setState({
        uncroppedVillianImage: e.target.files[0],
        villianURL: fileURL,
      }, () => {
        this.appendCharacterToCanvas(false);
      });
    }
  }

  generateBase64FromImage(imageFile) {
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (err) => reject(err);
    });

    reader.readAsDataURL(imageFile);
    return promise;
  }

  sliderChangeHandler(value, isHero, isRotater = false) {
    let rotaterValue = 0;
    let currentRotater2;
    let translateX;
    let translateY;
    let scalingFactor;
    let mainCanvas;
    // var xOffset
    // var yOffset
    if (isHero) {
      mainCanvas = document.getElementById('hero-cropper');
      if (isRotater) {
        rotaterValue = parseFloat(value) > this.state.heroRotaterValue ? 1 : -1;
      }
      currentRotater2 = this.state.heroRotaterValue + rotaterValue;
      translateX = this.state.heroImageOffsetX - this.state.heroImageOffsetXOld;
      translateY = this.state.heroImageOffsetY - this.state.heroImageOffsetYOld;
      // xOffset = this.state.heroImageOffsetX + this.state.heroImageOffsetXTotal
      // yOffset = this.state.heroImageOffsetY + this.state.heroImageOffsetYTotal
      scalingFactor = isRotater === false ? parseFloat(value) : this.state.heroSliderValue;
      const currentRotater = this.state.heroRotaterValue + rotaterValue;
      // console.log(currentRotater)
      if (!isRotater) {
        this.setState({
          heroSliderValue: parseFloat(value),
        });
      } else {
        this.setState({
          heroRotaterValue: currentRotater,
        });
      }
    } else if (!isHero) {
      mainCanvas = document.getElementById('villian-cropper');
      if (isRotater) {
        rotaterValue = parseFloat(value) > this.state.villianRotaterValue ? 1 : -1;
      }
      currentRotater2 = this.state.villianRotaterValue + rotaterValue;
      translateX = this.state.villianImageOffsetX - this.state.villianImageOffsetXOld;
      translateY = this.state.villianImageOffsetY - this.state.villianImageOffsetYOld;
      // xOffset = this.state.villianImageOffsetX + this.state.villianImageOffsetXTotal
      // yOffset = this.state.villianImageOffsetY + this.state.villianImageOffsetYTotal
      scalingFactor = isRotater === false ? parseFloat(value) : this.state.villianSliderValue;
      const currentRotater = this.state.villianRotaterValue + rotaterValue;
      // console.log(currentRotater)
      if (!isRotater) {
        this.setState({
          villianSliderValue: parseFloat(value),
        });
      } else {
        this.setState({
          villianRotaterValue: currentRotater,
        });
      }
    }
    const context = mainCanvas.getContext('2d');
    // console.log(xOffset, yOffset)
    const imageObj = new Image();
    imageObj.onload = (e) => {
      context.clearRect(-400, -400, 2.5 * mainCanvas.width, 2.5 * mainCanvas.height);
      // var destX = 0 + xOffset // Math.cos(currentRotater2 * Math.PI / 180) * xOffset + Math.sin(currentRotater2 * Math.PI /180) * yOffset;
      // var destY = 0 + yOffset// Math.sin(currentRotater2 * Math.PI / 180) * yOffset - Math.cos(currentRotater2 * Math.PI / 180) * xOffset;//Math.cos(currentRotater2 * Math.PI / 180) * xOffset + Math.cos(currentRotater2 * Math.PI / 180) * yOffset;
      // console.log(destY)
      const destWidth = imageObj.width * scalingFactor;
      const destHeight = imageObj.height * scalingFactor;
      context.rotate(currentRotater2 * Math.PI / 180);
      context.drawImage(imageObj, 0, 0, destWidth, destHeight);
      context.rotate(-currentRotater2 * Math.PI / 180);
      context.translate(translateX, translateY);
    };
    if (isHero) {
      imageObj.src = this.state.heroURL;
    } else if (!isHero) {
      imageObj.src = this.state.villianURL;
    }
  }

  changeCustomTextHandler(position, value) {
    this.setState({
      [position]: encodeURI(value),
    });
  }

  changeTextHandler(position, value) {
    const heroName = position === 'heroName' ? value : this.state.heroName;
    const cityName = position === 'cityName' ? value : this.state.cityName;
    const trophy = position === 'trophy' ? value : this.state.trophy;
    const villianName = position === 'villianName' ? value : this.state.villianName;
    this.setState({
      [position]: encodeURI(value),
      level1Text: encodeURI(`It was a cold and stormy day when ${heroName} set out from ${cityName} in search of ${trophy}. Soon enough the evil ${villianName} was being evil`),
      level2Text: encodeURI(`${heroName} saw ${villianName} hiding near the ${trophy}. "Stop right there ${villianName}!" yelled ${heroName}`),
      level3Text: encodeURI(`${heroName} finally found the ${trophy} In the swamps outside of ${cityName} the evil ${villianName} went home and cried for 6 days. ${heroName} got ice cream`),
    });
  }

  enemyChangeHandler(e) {
    this.setState({
      enemy: e.target.value,
    });
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]); let n = bstr.length; const
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  submitHandler() {
    // if (!this.state.)
    this.setState({ canSubmit: true });
    const hiddenCanvas = document.getElementById('hidden-canvas');
    hiddenCanvas.width = 578;
    hiddenCanvas.height = 660;
    const context = hiddenCanvas.getContext('2d');
    context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
    const imageObj = new Image();
    const villianImageObj = new Image();
    const imageObj2 = new Image();
    const imageObj3 = new Image();
    const imageObj4 = new Image();
    const imageObj5 = new Image();
    const imageObj6 = new Image();
    const scalingFactor = this.state.heroSliderValue;
    const xOffset = this.state.heroImageOffsetX + this.state.heroImageOffsetXTotal;
    const yOffset = this.state.heroImageOffsetY + this.state.heroImageOffsetYTotal;
    const villianScalingFactor = this.state.villianSliderValue;
    const villianXOffset = this.state.villianImageOffsetX + this.state.villianImageOffsetXTotal;
    const villianYOffset = this.state.villianImageOffsetY + this.state.villianImageOffsetYTotal;
    const { saveCanvasHandler } = this;
    imageObj.onload = (e) => {
      // let sx = imageObj.width * scalingFactor
      const destX = 0 + xOffset;
      const destY = 0 + yOffset;
      // var destX = -50 + Math.cos(currentRotater2 * Math.PI / 180) * xOffset + Math.sin(currentRotater2 * Math.PI /180) * yOffset;
      // var destY = -60 + Math.sin(currentRotater2 * Math.PI / 180) * yOffset - Math.cos(currentRotater2 * Math.PI / 180) * xOffset;
      const destWidth = imageObj.width * scalingFactor;
      const destHeight = imageObj.height * scalingFactor;
      context.translate(destX, destY);
      context.rotate(this.state.heroRotaterValue * Math.PI / 180);
      context.drawImage(imageObj, 0, 0, destWidth, destHeight);
      context.rotate(-this.state.heroRotaterValue * Math.PI / 180);
      context.translate(-destX, -destY);

      context.beginPath();
      context.rect(0, 0, imageObj.width, imageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      context.drawImage(imageObj2, 25, 290, 350, 360);
      const heroStandSprite = hiddenCanvas.toDataURL();
      saveCanvasHandler(heroStandSprite, 1);
      context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      /// Image 2
      context.translate(destX, destY);
      context.rotate(this.state.heroRotaterValue * Math.PI / 180);
      context.drawImage(imageObj, 0, 0, destWidth, destHeight);
      context.rotate(-this.state.heroRotaterValue * Math.PI / 180);
      context.translate(-destX, -destY);

      context.beginPath();
      context.rect(0, 0, imageObj.width, imageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      context.drawImage(imageObj3, 25, 290, 350, 360);
      const heroWalk1Sprite = hiddenCanvas.toDataURL();
      saveCanvasHandler(heroWalk1Sprite, 2);
      context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      /// Image 2
      context.translate(destX, destY);
      context.rotate(this.state.heroRotaterValue * Math.PI / 180);
      context.drawImage(imageObj, 0, 0, destWidth, destHeight);
      context.rotate(-this.state.heroRotaterValue * Math.PI / 180);
      context.translate(-destX, -destY);

      context.beginPath();
      context.rect(0, 0, imageObj.width, imageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      context.drawImage(imageObj4, 25, 290, 350, 360);
      const heroWalk2Sprite = hiddenCanvas.toDataURL();
      saveCanvasHandler(heroWalk2Sprite, 3);
      context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      /// Image 2
      context.translate(destX, destY);
      context.rotate(this.state.heroRotaterValue * Math.PI / 180);
      context.drawImage(imageObj, 0, 0, destWidth, destHeight);
      context.rotate(-this.state.heroRotaterValue * Math.PI / 180);
      context.translate(-destX, -destY);

      context.beginPath();
      context.rect(0, 0, imageObj.width, imageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      context.drawImage(imageObj5, 25, 290, 350, 360);
      const heroJumpSprite = hiddenCanvas.toDataURL();
      saveCanvasHandler(heroJumpSprite, 4);
      context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      /// Image 2
      context.translate(villianXOffset, villianYOffset);
      context.rotate(this.state.villianRotaterValue * Math.PI / 180);
      context.drawImage(villianImageObj, 0, 0, villianImageObj.width * villianScalingFactor, villianImageObj.height * villianScalingFactor);
      context.rotate(-this.state.villianRotaterValue * Math.PI / 180);
      context.translate(-villianXOffset, -villianYOffset);

      context.beginPath();
      context.rect(0, 0, villianImageObj.width, villianImageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      context.drawImage(imageObj6, 25, 290, 350, 360);
      const villianSprite = hiddenCanvas.toDataURL();
      saveCanvasHandler(villianSprite, 5);
      context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      /// Image 2
      context.drawImage(imageObj, destX, destY, destWidth, destHeight);
      context.beginPath();
      context.rect(0, 0, imageObj.width, imageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      const faceOnly = hiddenCanvas.toDataURL();
      saveCanvasHandler(faceOnly, 6);
    };
    imageObj.src = this.state.heroURL;
    villianImageObj.src = this.state.villianURL;
    imageObj2.src = Stand;
    imageObj3.src = Walk1;
    imageObj4.src = Walk2;
    imageObj5.src = Jump;
    imageObj6.src = Villian;
  }

  validationHandler() {
    const errors = {
      heroNameError: false,
      villianNameError: false,
      cityNameError: false,
      trophyError: false,
      level1TextError: false,
      level2TextError: false,
      level3TextError: false,
      enemyError: false,
      errorExists: false,
    };
    if (this.state.enemy === '') {
      errors.enemyError = true;
      errors.errorExists = true;
    }
    if (!this.state.customText) {
      if (this.state.heroName === '' || this.state.heroName === 'hero') {
        errors.heroNameError = true;
        errors.errorExists = true;
      }
      if (this.state.villianName === '' || this.state.villianName === 'villian') {
        errors.villianNameError = true;
        errors.errorExists = true;
      }
      if (this.state.cityName === '' || this.state.cityName === 'city') {
        errors.cityNameError = true;
        errors.errorExists = true;
      }
      if (this.state.trophy === '' || this.state.trophy === 'prize') {
        errors.trophyError = true;
        errors.errorExists = true;
      }
    } else {
      if (this.state.level1Text === '') {
        errors.level1TextError = true;
        errors.errorExists = true;
      }
      if (this.state.level2Text === '') {
        errors.level2TextError = true;
        errors.errorExists = true;
      }
      if (this.state.level3Text === '') {
        errors.level3TextError = true;
        errors.errorExists = true;
      }
    }
    this.setState({
      heroNameError: errors.heroNameError,
      villianNameError: errors.villianNameError,
      cityNameError: errors.cityNameError,
      trophyError: errors.trophyError,
      level1TextError: errors.level1TextError,
      level2TextError: errors.level2TextError,
      level3TextError: errors.level3TextError,
      enemyError: errors.enemyError,
    });
    if (errors.errorExists) {
      return true;
    }
    return false;
  }

  async submitGameHandler() {
    if (this.validationHandler()) return;
    const formData = new FormData();
    // let hero1 = await this.generateBase64FromImage(this.state.heroStandSprite)
    formData.append('gameId', this.state.gameId);
    formData.append('images', this.dataURLtoBlob(this.state.heroStandSprite), 'heroStandSprite');
    formData.append('images', this.dataURLtoBlob(this.state.heroWalk1Sprite), 'heroWalk1Sprite');
    formData.append('images', this.dataURLtoBlob(this.state.heroWalk2Sprite), 'heroWalk2Sprite');
    formData.append('images', this.dataURLtoBlob(this.state.heroJumpSprite), 'heroJumpSprite');
    formData.append('images', this.dataURLtoBlob(this.state.villianSprite), 'villianSprite');
    formData.append('images', this.dataURLtoBlob(this.state.faceOnly), 'faceOnly');
    fetch(`${config.backend}/client-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((fileResData) => {
        console.log(fileResData);
        const imageUrls = fileResData.filePaths;
        console.log(this.state);
        return fetch(`${config.backend}/game/create-game`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.state.gameId,
            level1Text: this.state.level1Text,
            level2Text: this.state.level2Text,
            level3Text: this.state.level3Text,
            enemy: this.state.enemy,
            image1: imageUrls[0],
            image2: imageUrls[1],
            image3: imageUrls[2],
            image4: imageUrls[3],
            image5: imageUrls[4],
            image6: imageUrls[5],
          }),
        });
      })
      .then((res) => res.json())
      .then((resData) => {
        console.log(resData);
        if (resData.errors && resData.errors[0].status === 422) {
          throw new Error(
            "Validation failed. Make sure the email address isn't used yet!",
          );
        }
        if (resData.errors) {
          console.log(resData.errors);
          throw new Error('User login failed!');
        }
        navigate(`/secure/game/${this.state.gameId}`);
      });
  }

  render() {
    return (
      <Layout>
        {!this.state.customText
          ? (
            <table>
              <tbody>
                <tr>
                  <th width="50%" style={{ borderBottom: '1px solid #000000' }}>
                    <h4>Level 1 Text</h4>
                    <p>
                      It was a cold and stormy day when
                      <span style={{ color: 'orange' }}>{this.state.heroName}</span>
                      {' '}
                      set out from
                      <span style={{ color: 'orange' }}>{this.state.cityName}</span>
                      {' '}
                      in search of
                      <span style={{ color: 'orange' }}>{this.state.trophy}</span>
                      . Soon enough the evil
                      <span style={{ color: 'orange' }}>{this.state.villianName}</span>
                      {' '}
                      was being evil
                    </p>
                    <h4>Level 2 Text</h4>
                    <p>
                      <span style={{ color: 'orange' }}>{this.state.heroName}</span>
                      {' '}
                      saw
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.villianName}</span>
                      {' '}
                      hiding near the
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.trophy}</span>
                      . Stop right there
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.villianName}</span>
                      {' '}
                      yelled
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.heroName}</span>
                    </p>
                    <h4>Level 3 Text</h4>
                    <p>
                      <span style={{ color: 'orange' }}>{this.state.heroName}</span>
                      {' '}
                      finally found the
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.trophy}</span>
                      . In the swamps outside of
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.cityName}</span>
                      {' '}
                      the evil
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.villianName}</span>
                      {' '}
                      went home and cried for 6 days.
                      {' '}
                      <span style={{ color: 'orange' }}>{this.state.heroName}</span>
                      {' '}
                      got ice cream
                    </p>
                  </th>
                  <th width="50%" style={{ borderBottom: '1px solid #000000' }}>
                    <form>
                      <label htmlFor="heroname">Hero's Name</label>
                      {' '}
                      {this.state.heroNameError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="heroname" className="signup-input-element" onChange={(e) => this.changeTextHandler('heroName', e.target.value)} onClick={() => { this.setState({ heroNameError: false }); }} />
                      <br />
                      <label htmlFor="cityname">City's Name</label>
                      {' '}
                      {this.state.cityNameError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="cityname" className="signup-input-element" onChange={(e) => this.changeTextHandler('cityName', e.target.value)} onClick={() => { this.setState({ cityNameError: false }); }} />
                      <br />
                      <label htmlFor="trophyname">Trophy's Name</label>
                      {' '}
                      {this.state.trophyError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="trophyname" className="signup-input-element" onChange={(e) => this.changeTextHandler('trophy', e.target.value)} onClick={() => { this.setState({ trophyError: false }); }} />
                      <br />
                      <label htmlFor="villianName">Villian's Name</label>
                      {' '}
                      {this.state.villianNameError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="villianName" className="signup-input-element" onChange={(e) => this.changeTextHandler('villianName', e.target.value)} onClick={() => { this.setState({ villianNameError: false }); }} />
                      <br />
                      <br />
                      <button onClick={() => {
                        this.setState({
                          customText: true, level1Text: '', level2Text: '', level3Text: '',
                        });
                      }}
                      >
                        Or Write Your Own level Text
                      </button>
                    </form>
                  </th>
                </tr>
              </tbody>
            </table>
          )
          : (
            <div style={{ borderBottom: '1px solid #000000', paddingBottom: '2rem', marginBottom: '2rem' }}>
              <label htmlFor="custom-level-1-text">Level 1 Text</label>
              {' '}
              {this.state.level1TextError ? <span className="error">Please Enter a Value</span> : null}
              <br />
              <br />
              <textarea name="custom-level-1-text" id="custom-level-1-text" onChange={(e) => { this.changeCustomTextHandler('level1Text', e.target.value); }} onClick={() => { this.setState({ level1TextError: false }); }} />
              <br />
              <br />
              <label htmlFor="custom-level-2-text">Level 2 Text</label>
              {' '}
              {this.state.level2TextError ? <span className="error">Please Enter a Value</span> : null}
              <br />
              <br />
              <textarea name="custom-level-2-text" id="custom-level-2-text" onChange={(e) => { this.changeCustomTextHandler('level2Text', e.target.value); }} onClick={() => { this.setState({ level2TextError: false }); }} />
              <br />
              <br />
              <label htmlFor="custom-level-3-text">Level 3 Text</label>
              {' '}
              {this.state.level3TextError ? <span className="error">Please Enter a Value</span> : null}
              <br />
              <br />
              <textarea name="custom-level-3-text" id="custom-level-3-text" onChange={(e) => { this.changeCustomTextHandler('level3Text', e.target.value); }} onClick={() => { this.setState({ level3TextError: false }); }} />
              <br />
              <br />
              <button onClick={() => { this.setState({ customText: false }, () => { this.changeTextHandler(); }); }}>Use Template</button>
            </div>
          )}
        <label>Hero Image</label>
        <br />
        <canvas id="hidden-canvas" width="1" height="1" style={{ display: 'none' }} />
        <input type="file" onChange={(e) => this.uploadHandler(e, true)} />
        <div style={{ display: 'none' }}>
          <img id="hero-image-element" src={this.state.heroURL} />
        </div>
        <div style={{ position: 'relative' }}>
          <canvas id="hero-cropper" width="1" height="1" />
          <canvas
            id="hero-cropper-overlay"
            width="1"
            height="1"
            style={{
              position: 'absolute', left: '0', top: '0', zIndex: '100',
            }}
          />
        </div>
        <div className={`${this.state.sliderVisible ? '' : 'hidden '}slidecontainer`}>
          <br />
          <label>Rotate</label>
          <br />
          <input type="range" min="-90" max="90" step="1" value={this.state.heroRotaterValue} className="round-slider" id="myRotationRange" onChange={(e) => this.sliderChangeHandler(e.target.value, true, true)} />
          <br />
          <label>Resize</label>
          <br />
          <input type="range" min="0" max="2" step="0.01" value={this.state.heroSliderValue} className="slider" id="myRange" onChange={(e) => this.sliderChangeHandler(e.target.value, true)} />
        </div>
        <hr />
        <label>Villian Image</label>
        <br />
        <input type="file" onChange={(e) => this.uploadHandler(e, false)} />
        <div style={{ position: 'relative' }}>
          <canvas id="villian-cropper" width="1" height="1" />
          <canvas
            id="villian-cropper-overlay"
            width="1"
            height="1"
            style={{
              position: 'absolute', left: '0', top: '0', zIndex: '100',
            }}
          />
        </div>
        <div className={`${this.state.villianSliderVisible ? '' : 'hidden '}slidecontainer`}>
          <br />
          <label>Rotate</label>
          <br />
          <input type="range" min="-90" max="90" step="1" value={this.state.villianRotaterValue} className="round-slider" id="myVillianRotationRange" onChange={(e) => this.sliderChangeHandler(e.target.value, false, true)} />
          <br />
          <label>Resize</label>
          <br />
          <input type="range" min="0" max="2" step="0.01" value={this.state.villianSliderValue} className="slider" id="myVillianRange" onChange={(e) => this.sliderChangeHandler(e.target.value, false)} />
        </div>
        <br />
        <button onClick={() => this.submitHandler()} disabled={!this.state.heroURL || !this.state.villianURL}>Create Characters!</button>
        <br />
        <br />
        {this.state.heroStandSprite && this.state.villianSprite ? (
          <div>
            <p style={{ margin: 'auto' }}>
              <img src={this.state.heroStandSprite} style={{ float: 'left', width: '50%' }} />
              <img src={this.state.villianSprite} style={{ float: 'left', width: '50%' }} />
            </p>
            <div style={{ clear: 'both' }} />
          </div>
        ) : null}
        <hr />
        <label htmlFor="enemy">Enemy</label>
        {' '}
        {this.state.enemyError ? <span className="error">Please Choose a Value</span> : null}
        <br />
        <select name="enemy" id="enemy" onChange={(e) => { this.enemyChangeHandler(e); }} onClick={() => { this.setState({ enemyError: false }); }}>
          <option value="">Please Select</option>
          <option value="dinosaurs">Dinosaurs</option>
          <option value="rats">Rats</option>
          <option value="ghosts">Ghosts</option>
          <option value="beliebers">Beliebers</option>
          {' '}
          {/* MAKE ONE OR TWO FUNNY OPTIONS THAT EVERYONE CAN LAUGH AT, LIKE KARENS BUT LESS POLARIZING */}
        </select>
        <br />
        <br />
        <button onClick={() => this.submitGameHandler()} disabled={!this.state.canSubmit}>{!this.state.canSubmit ? 'Please Create Characters' : 'Create Game!'}</button>
      </Layout>
    );
  }
}

export default Game;
